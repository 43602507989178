<template>
  <div>
    <Header />
    <div class="container">
      <h2>Manage Domains</h2>
      <CreateDomain />
      <h2>Domain List</h2>
      <DomainList />
    </div>

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import CreateDomain from '@/components/CreateDomain'
import DomainList from '@/components/DomainList'
export default {
  components: {Header,CreateDomain,DomainList},
  setup() {
  },
}
</script>
