<template>
  <div class="about">
    <div>
        <Toolbar class="p-mb-2" v-for="{id, name} in domains" :key="id">
            <template #left>
                <div class="font-black" >{{name}}</div>
            </template>

            <template #right>
                <router-link class="no-decor" :to="`/editdomain/${id}`">
                    <Button icon="pi pi-pencil" class="p-mr-2" />
                </router-link>
                <Button icon="pi pi-trash" class="p-button-danger" @click="deleteDomain(id)" />
            </template>
        </Toolbar>
    </div>
  </div>
</template>

<script>
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import {useLoadDomains, deleteDomain} from '@/fbDomain'

export default {
  components: {Toolbar,Button},
  setup() {
    const domains = useLoadDomains()
    return {domains, deleteDomain}
  },
}
</script>

<style>
</style>

