<template>
  <div class="">
    <form @submit.prevent="onSubmit">
      <div>
        <InputText class="p-mb-2 p-mt-2" type="text" placeholder="Insert Domain Name" v-model="form.name" required />
      </div>
      <Button label="Register Domain" type="submit" />
    </form>
    <transition-group name="p-message" tag="div">
        <Message v-for="msg of messages" :severity="msg.severity" :life="3000" :sticky="false" :key="msg.id">{{msg.content}}</Message>
    </transition-group>

  </div>
</template>

<script>
import {createDomain} from '@/fbDomain'
import {reactive} from 'vue'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {ref} from 'vue';
import Message from 'primevue/message';

export default {
  components: {InputText, Button, Message},
  setup() {

      const messages = ref([]);
        const count = ref(0);
        const addMessages = () => {
            messages.value = [
                {severity: 'success', content: 'Domain Added Successfully', id: count.value++},
            ]
        };

    const form = reactive({name:''})

    const onSubmit = async () =>{
      await createDomain({...form})
      form.name = ''
      addMessages()
    }


    return {form,  onSubmit, messages, count, addMessages }
  },
}
</script>

